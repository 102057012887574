import Cookies from 'js-cookie'
const generalWatches = {
  computed: {
    events() {
      return this.$store.getters['events']
    },
  },
  watch: {
    events({ length: newValue }, { length: oldValue }) {
      if (!newValue && oldValue) {
        this.$store.commit('counterTodo/destroyAll')
        this.$store.commit('counterReport/destroy')
      }
    },
  },
  mounted() {
    window.addEventListener('click', () => {
      const isPathValid = ['/', '/example'].includes(this.$route.path)
      const isTeamCookieAbsent = !Cookies.get('team')
      if (!isPathValid && isTeamCookieAbsent) {
        window.location.href = '/?logout_for_no_use=true'
      }
    })
    if (!this.events.length) {
      if (this.$store.getters.newTodoCounter.length) {
        this.$store.commit('counterTodo/destroyAll')
      }
      if (this.$store.getters.newReportCounter.length) {
        this.$store.commit('counterReport/destroy')
      }
    }
  },
}

export default generalWatches
