<template>
    <div class="example">
        <h1 class="text-h3 text-center mt-8">דף דוגמה</h1>
        <v-container>
            <event-user />
        </v-container>
    </div>
</template>

<script>
import EventUser from '@/views/EventUser'
export default {
  name: 'example-page',
  components: {
    EventUser
  },
  data() {
    return {
      originalXHR: null
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Login' })
    },
    setupMockXHR() {
      // Store the original XMLHttpRequest
      this.originalXHR = window.XMLHttpRequest
            
      // Create a complete MockXHR implementation
      window.XMLHttpRequest = function() {
        const mockXHR = {
          url: '',
          method: '',
          readyState: 0,
          status: 0,
          statusText: '',
          responseText: '',
          async: true,
          headers: {},
          onreadystatechange: null,
          onload: null,
          onerror: null,
                    
          open: function(method, url, async = true) {
            console.log(`Intercepted request to: ${url}`)
            this.url = url
            this.method = method
            this.async = async
            this.readyState = 1
          },
                    
          setRequestHeader: function(name, value) {
            this.headers[name] = value
          },
                    
          send: function(body) {
            console.log(`Intercepted body: ${body}`)
                        
            const xhr = this
            setTimeout(() => {
              // Update properties
              xhr.readyState = 4
              xhr.status = 200
              xhr.statusText = "OK"
              xhr.responseText = body || JSON.stringify({ success: true, data: "Mocked response" })
                            
              // Trigger callbacks
              if (xhr.onreadystatechange) {
                xhr.onreadystatechange()
              }
                            
              if (xhr.onload) {
                xhr.onload()
              }
            }, 100)
          },
                    
          abort: function() {
            console.log('Request aborted')
          },
                    
          getAllResponseHeaders: function() {
            let headerString = ''
            for (const key in this.headers) {
              headerString += `${key}: ${this.headers[key]}\r\n`
            }
            return headerString
          },
                    
          getResponseHeader: function(name) {
            return this.headers[name] || null
          }
        }
                
        // Add getters
        Object.defineProperty(mockXHR, 'responseURL', {
          get: function() { return this.url }
        })
                
        return mockXHR
      }
            
      console.log('XHR mock installed')
    },
    restoreXHR() {
      if (this.originalXHR) {
        window.XMLHttpRequest = this.originalXHR
        console.log('Original XHR restored')
      }
    },
    cleanup() {
      this.restoreXHR()
      this.$store.commit('setUserName', null)
    }
  },
  beforeRouteLeave(to, from, next) {
    // Run cleanup when navigating away with Vue Router
    console.log('Navigation detected, cleaning up...')
    this.cleanup()
    next()
  },
  mounted() {
    this.setupMockXHR()
    this.$store.commit('setUserName', 'ישראל ישראלי')
    this.$store.commit('events/set', [
      {
        "location": {
          "type": "Point",
          "coordinates": [
            [
              32.1171947,
              35.2509961
            ]
          ]
        },
        "_id": "674dac77ccf1fe436e847a24",
        "category": "intruder",
        "content": null,
        "team": "6460eb12c6eafada5911ae62",
        "openedBy": "4545",
        "isActive": true,
        "todos": [
          {
            "title": "עדכון נוכחות",
            "comment": null,
            "category": "educational",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 0,
            "_id": "674dac77ccf1fe436e847a54",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "יצירת קשר עם אגף החינוך וקבלת תמונת מצב על מוסדות החינוך ביישוב",
            "comment": "אבי אלון    052-431-7246\nמולי יסלזון  050-620-5129",
            "category": "educational",
            "link": "0524317246",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 1,
            "_id": "674dac77ccf1fe436e847a55",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "יצירת קשר ישיר עם גני הילדים, מעונות וכו' ביישוב",
            "comment": "להוסיף קישור לרשימת מוסדות החינוך ביישוב",
            "category": "educational",
            "link": null,
            "differentialLinks": [
              {
                "link": "https://docs.google.com/document/d/1t4i8vS7gsPWxs7pIhSejW9CFb9vbrhp8/edit?usp=drive_link&ouid=106293249084642331108&rtpof=true&sd=true",
                "team": "63cec71943d220329ff589d6",
                "_id": "65ae713bc2b31871b4f57fcc"
              }
            ],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 2,
            "_id": "674dac77ccf1fe436e847a56",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "יצירת קשר עם חוגים ביישוב שפועלים בזמן האירוע וקבלת תמונת מצב",
            "comment": "ניתן להכניס קישור לתכנית חוגים ביישוב",
            "category": "educational",
            "link": null,
            "differentialLinks": [
              {
                "link": "https://docs.google.com/spreadsheets/d/1-MFtHrqSwdJ8fZsxLLsmvbH_hGE4U6k6fyyAPo3zryQ/edit?usp=sharing",
                "team": "63cec71943d220329ff589d6",
                "_id": "65a52bbfc2b31871b4f57c06"
              }
            ],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 3,
            "_id": "674dac77ccf1fe436e847a58",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "עידכון של אגף החינוך על כל מעני החינוך ביישוב",
            "comment": null,
            "category": "educational",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 4,
            "_id": "674dac77ccf1fe436e847a5a",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "אם מעורב מוסד חינוכי - הקמת חמ\"ל תקשורת עם הורי הילדים",
            "comment": "בתיאום עם אגף החינוך",
            "category": "educational",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 5,
            "_id": "674dac77ccf1fe436e847a5b",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "היום שאחרי - התערבויות לאוכלוסיות יעד בשיתוף תא רווחה ",
            "comment": null,
            "category": "educational",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 6,
            "_id": "674dac77ccf1fe436e847a5c",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "תיאום עם אגף החינוך והשפ\"ח להתערבויות במוסדות החינוך",
            "comment": null,
            "category": "educational",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 7,
            "_id": "674dac77ccf1fe436e847a5d",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "נתוני תלמידים במוסדות החינוך",
            "comment": null,
            "category": "educational",
            "link": null,
            "differentialLinks": [
              {
                "link": "https://docs.google.com/spreadsheets/d/1msiWxB1Iu2IJ2tWlacQIz8eEmtarjzp5AF3nGMMGisQ/edit?usp=drive_link",
                "team": "63cec7ae595af0f6c260dc88",
                "_id": "650aa31a45d8989a082332cc"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/12VYXR5dvTIz5BffJNvcHQicC-PsqvCX_pvi863YXwSg/edit?usp=sharing",
                "team": "63cec7edc06be7a429ada14c",
                "_id": "650aa31a45d8989a082332cd"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/1hUFbzTE08QrDO79Mwp_pwMIA3HsbZ5pU0UtRkE5zdF0/edit?usp=sharing",
                "team": "63c55f92fb9ffa4575a6c01a",
                "_id": "650aa31a45d8989a082332ce"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/1Zu1lbmbBjEI89H675lJAQc629QTNI-lMwFaalIwIr2Q/edit?usp=sharing",
                "team": "63cec7cc6479bf515f0e200a",
                "_id": "650aa31a45d8989a082332cf"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/10rJ82SNZ-8-29M2H3-Bke1TNiEoPJBw9c_ejmLwamTM/edit?usp=sharing",
                "team": "63cec78a263c4160d6b86a30",
                "_id": "650aa31a45d8989a082332d0"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/1W03XQ2CDpHkCHRf1OPGJKHy-5qKyRx_Mz2i-NElbpcU/edit?usp=sharing",
                "team": "63cec70af5c5aa950b3a3e23",
                "_id": "650aa31a45d8989a082332d1"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/1U9HzdHv469OR8zph4JO_oCgOtVsOBwq_P56Q8-zJbrs/edit?usp=sharing",
                "team": "63cec725fe064d793156c4f1",
                "_id": "650aa31a45d8989a082332d2"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/1iUNyIDzN99z6M0IqbYMXYuWxrs7WMJ0ynz67XCi4ix0/edit?usp=sharing",
                "team": "63cec7329652e0c7573289db",
                "_id": "650aa31a45d8989a082332d3"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/1-VIBJGcVVMHL9t0zlIpMkGaX_DByWV9oa34kXTi8H5E/edit?usp=sharing",
                "team": "63cec7a2944d38be8b20bb58",
                "_id": "650aa31a45d8989a082332d4"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/1ALEBkPwFxlbGG-W5CQuq2umy2WCxLTsSJuBXBtskgTI/edit?usp=sharing",
                "team": "63cec768b9057eafd92f54d2",
                "_id": "650aa31a45d8989a082332d5"
              },
              {
                "link": "https://docs.google.com/spreadsheets/d/175q_qZAsxiyjsbmjTKqEDsB2UFLJyRERiIv3vNbnknU/edit?gid=65059190#gid=65059190",
                "team": "63cec7d96acb3eefd60f5192",
                "_id": "66d704f0d7f768a58ce9d5af"
              }
            ],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 8,
            "_id": "674dac77ccf1fe436e847a5e",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "נתוני הגיל הרך ביישוב",
            "comment": "ניתן להוסיף קישור לנתוני הגיל הרך ביישוב",
            "category": "educational",
            "link": null,
            "differentialLinks": [
              {
                "link": "https://docs.google.com/spreadsheets/d/1yqZhMn3SbfKdv78o-puMXFENqgPRxXutz_fA8RQT66A/edit#gid=0",
                "team": "63cec71943d220329ff589d6",
                "_id": "65b62311c2b31871b4f5815d"
              }
            ],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 9,
            "_id": "674dac77ccf1fe436e847a73",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "הודעה והנחיות להורים בעקבות האירוע",
            "comment": null,
            "category": "educational",
            "link": "https://docs.google.com/document/d/1VWloJzTsaVWS83iNrkKPwNQZfie1_dBkrLW5fAnKJyY/edit?usp=sharing",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 10,
            "_id": "674dac77ccf1fe436e847a75",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "דיווח למוקד",
            "comment": null,
            "category": "headQuaters",
            "link": "1208",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 30,
            "index": 1,
            "_id": "674dac77ccf1fe436e847a76",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2025-02-02T10:20:16.088Z"
          },
          {
            "title": "פתיחת ערוץ תקשורת עם הרבש\"צ (דרך תא ביטחון, סגן רבש\"צ וכדו'",
            "comment": null,
            "category": "headQuaters",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 4,
            "_id": "674dac77ccf1fe436e847a77",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2025-02-02T10:20:16.088Z"
          },
          {
            "title": "הפעלת צח\"י, כ\"כ,, רבש\"צ, צוות מזכירות",
            "comment": null,
            "category": "headQuaters",
            "link": null,
            "differentialLinks": [
              {
                "link": "https://drive.google.com/drive/u/3/folders/1hRq5A3Sb5LRc7BS00W1Cs2JtTWlDI3v_",
                "team": "63cec77327e778fd433a9326",
                "_id": "661256e7ac6b9127e7f8e3a6"
              }
            ],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 7,
            "_id": "674dac77ccf1fe436e847a78",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2025-02-02T10:20:16.087Z"
          },
          {
            "title": "ביצוע ירוק בעיניים (אשכולות)",
            "comment": null,
            "category": "headQuaters",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 10,
            "_id": "674dac77ccf1fe436e847a7a",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2025-02-02T10:20:16.087Z"
          },
          {
            "title": "עדכון מוקד",
            "comment": "דרכו ל מד\"א, צבא, עו\"ס כונן, מלווה קהילתי",
            "category": "headQuaters",
            "link": "1208",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 13,
            "_id": "674dac77ccf1fe436e847a7b",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2025-02-02T10:20:16.087Z"
          },
          {
            "title": "ביצוע הערכת מצב וחלוקת תפקידים בצוות",
            "comment": "מצורף קישור לעזר הערכת מצב\n(יש להוריד את הקובץ כדי לכתוב בו)",
            "category": "headQuaters",
            "link": "https://docs.google.com/document/d/10vnqb7EgPHzv1K-TpJPYm5vZderwBx9cvpUZqNXCK0Y/edit?usp=sharing",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 20,
            "index": 15,
            "_id": "674dac77ccf1fe436e847a7c",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2025-02-02T10:20:16.087Z"
          },
          {
            "title": "עדכון מוקד (שוטף)",
            "comment": "מצורף קישור לדף קשר בעלי תפקידים במועצה",
            "category": "headQuaters",
            "link": "https://drive.google.com/file/d/1LKToOfw8fO_UCJsQnOJtYHTc1M1dcEc5/view",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 30,
            "index": 14,
            "_id": "674dac77ccf1fe436e847a7d",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2025-02-02T10:20:16.087Z"
          },
          {
            "title": "עדכון נוכחות",
            "comment": null,
            "category": "logistics",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 0,
            "_id": "674dac77ccf1fe436e847a7e",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "הכוונת כוחות וניתוב תנועה לנקודות האירוע-  בכפוף לאישור יציאה מהבתים ע\"י הצבא",
            "comment": null,
            "category": "logistics",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 1,
            "_id": "674dac77ccf1fe436e847a7f",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "הדרכה של כוחות הצבא ביישוב, מפתחות למבני ציבור וכדו'",
            "comment": null,
            "category": "logistics",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 2,
            "_id": "674dac77ccf1fe436e847a80",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "הקמת חמ\"ל ומתחם תקשורת במידת הצורך ",
            "comment": "בכפוף לאישור יציאה מהבתים ע\"י הצבא",
            "category": "logistics",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 60,
            "index": 3,
            "_id": "674dac77ccf1fe436e847a81",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "עדכון נוכחות",
            "comment": null,
            "category": "medic",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 0,
            "_id": "674dac77ccf1fe436e847a82",
            "createdAt": "2024-12-02T12:47:51.388Z",
            "updatedAt": "2024-12-02T12:47:51.388Z"
          },
          {
            "title": "אם יש צורך - הגשת עזרה ראשונה מיידית עד להגעת כוחות החירום",
            "comment": null,
            "category": "medic",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 1,
            "_id": "674dac77ccf1fe436e847a83",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "שמירת קשר רציף עם נציג גורמי הרפואה במועצה ומד\"א",
            "comment": "\nטלפון של אסף וילף אחראי מד\"א במועצה 050-401-2041",
            "category": "medic",
            "link": "0504012041",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 30,
            "index": 2,
            "_id": "674dac77ccf1fe436e847a84",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "ניהול טבלת מעקב לשמירת קשר עם מטופלים שפונו  ",
            "comment": " קישור לטבלת מעקב למפונים\nיש להוריד את הטבלה כדי לכתוב בה",
            "category": "medic",
            "link": "https://docs.google.com/spreadsheets/d/13MdBxvgIPCLKiwJ_ylQzqi9d2Kk_eyt7wset4R_dd0A/edit?usp=share_link",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 30,
            "index": 3,
            "_id": "674dac77ccf1fe436e847a85",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "מעקב אחרי אוכלוסיות תל\"ם רפואי. ייעוץ, תיאום, והגשת סיוע לפי הצורך.",
            "comment": "להכניס קישור לרשימת אוכלוסיות תל\"מ רפואי",
            "category": "medic",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 4,
            "_id": "674dac77ccf1fe436e847a86",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "מעקב אחרי נפגעי חרדה / עדי ראייה בשטח. הפנייה לצוות רווחה",
            "comment": null,
            "category": "medic",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 5,
            "_id": "674dac77ccf1fe436e847a87",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "יצירת קשר והפעלת שירותי רפואה ביישוב אם יש",
            "comment": "להוסיף קישור למיפוי של שירותי הרפואה ביישוב.",
            "category": "medic",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 6,
            "_id": "674dac77ccf1fe436e847a88",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "עדכון נוכחות",
            "comment": null,
            "category": "pr",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 0,
            "_id": "674dac77ccf1fe436e847a89",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "הודעה לתושבים - הנחיות  ",
            "comment": "יש לאשר הודעות דוברות עם יו\"ר הצוות\nולבדוק פעמיים את אמינות המידע שאתם מקבלים\nמצורף קישור למאגר הודעות נצורות",
            "category": "pr",
            "link": "https://docs.google.com/document/d/1RbMLJdVAQDYT4JKJCj5v7_QplvbPK8PLxI76BAL1tiQ/edit?usp=sharing",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 20,
            "index": 1,
            "_id": "674dac77ccf1fe436e847a8a",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "לבצע נוהל ירוק בעיניים",
            "comment": "להכניס קישור לרשימות אשכולות",
            "category": "pr",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 2,
            "_id": "674dac77ccf1fe436e847a8b",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "תיאום עם דוברות המועצה בפרטי האירוע ובשמות נפגעים אם יש",
            "comment": "דובר המועצה, אור יזרעאלי.\n054-666-8328",
            "category": "pr",
            "link": "0546668328",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 3,
            "_id": "674dac77ccf1fe436e847a8c",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "פרסום שמות הנפגעים - רק לאחר הודעה רשמית ואישור המשפחה!",
            "comment": null,
            "category": "pr",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 30,
            "index": 4,
            "_id": "674dac77ccf1fe436e847a8d",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "מינוי איש קשר עם התקשורת",
            "comment": "יש לעדכן התושבים בזהות איש הקשר ",
            "category": "pr",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 5,
            "_id": "674dac77ccf1fe436e847a8e",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "הכנת דף מסרים לתקשורת",
            "comment": "יש לתאם את דף המסרים עם יו\"ר הצוות ועם דוברות המועצה",
            "category": "pr",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 6,
            "_id": "674dac77ccf1fe436e847a8f",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "הקמת מתחם לקבלת אנשי תקשורת ביישוב",
            "comment": "בסיוע תא לוגיסטיקה",
            "category": "pr",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 7,
            "_id": "674dac77ccf1fe436e847a90",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "משפחות נפגעים - סיוע בעבודה עם התקשורת (דובר משפחתי , מסרים לתקשורת, תמונות..)",
            "comment": "לחבר גם את דוברות המועצה לעבודה עם המשפחות",
            "category": "pr",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 8,
            "_id": "674dac77ccf1fe436e847a91",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "עדכון נוכחות",
            "comment": null,
            "category": "security",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 1,
            "_id": "674dac77ccf1fe436e847a92",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2025-02-28T06:42:58.406Z"
          },
          {
            "title": "תקשורת בין כ\"כ וגורם צבא בשטח לבין הצח\"י",
            "comment": null,
            "category": "security",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 4,
            "_id": "674dac77ccf1fe436e847a93",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2025-02-28T06:42:58.406Z"
          },
          {
            "title": "הכנת עזרים ומפות לצורך תקשורת עם הצבא",
            "comment": " קישור לעזרים ומפות",
            "category": "security",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 7,
            "_id": "674dac77ccf1fe436e847a94",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2025-02-28T06:42:58.406Z"
          },
          {
            "title": "סיכום ממצאים של אזורי סריקה ואשכולות",
            "comment": null,
            "category": "security",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 10,
            "_id": "674dac77ccf1fe436e847a95",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2025-02-28T06:42:58.399Z"
          },
          {
            "title": "עדכון נוכחות",
            "comment": null,
            "category": "social",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 0,
            "_id": "674dac77ccf1fe436e847a96",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "לסייע לדוברות בניסוח ההודעות לציבור",
            "comment": null,
            "category": "social",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 1,
            "_id": "674dac77ccf1fe436e847a97",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "יצירת קשר עם עו\"ס כונן ומרכז חוסן",
            "comment": "עוס כונן דרך מוקד 1208\nאו דרך המשרד בשעות העבודה: 029939935",
            "category": "social",
            "link": "1208",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 2,
            "_id": "674dac77ccf1fe436e847a98",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "יצירת קשר טלפוני ובדיקת דופק עם אוכלוסיות תל\"מ ",
            "comment": "להכניס קישור לרשימת אוכלוסיות תל\"מ",
            "category": "social",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 3,
            "_id": "674dac77ccf1fe436e847a99",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "לחתור לקבל את שמות הנפגעים בהקדם",
            "comment": null,
            "category": "social",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 20,
            "index": 4,
            "_id": "674dac77ccf1fe436e847a9a",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "בתיאום עם רווחה ועוס כונן - יצירת קשר עם המשפחות (קישור - נהלי הודעה למשפחות)",
            "comment": null,
            "category": "social",
            "link": "https://drive.google.com/file/d/10vXdSFaLpnPKFd2ixCjWPu3zM8uhXsJs/view?usp=sharing",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 5,
            "_id": "674dac77ccf1fe436e847a9b",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "ליווי רציף של המשפחות עד לסוף האירוע",
            "comment": "זיהוי צרכים, קישור לגורמי טיפול, הפעלת מתנדבים לסיוע\nמצורף קישור לטבלת מעקב לליווי משפחות\n",
            "category": "social",
            "link": null,
            "differentialLinks": [
              {
                "link": "https://docs.google.com/document/d/1xfv0xk9zlpgAr8I4Cx-nhJ6CYHehkDDIQSzmhMhGDV4/edit?usp=drive_link",
                "team": "63cec7e37382d29b34485c24",
                "_id": "661aebd8ac6b9127e7f930ea"
              },
              {
                "link": "https://docs.google.com/document/d/1zC1f0UaK9FYvP39BaSZ2E46HQFa6chq4EvfWCqO39LY/edit?usp=drive_link",
                "team": "63cec7edc06be7a429ada14c",
                "_id": "661aebd8ac6b9127e7f930eb"
              },
              {
                "link": "https://docs.google.com/document/d/1qHcJXSnDs4wsPZNhIakFlBdcrRiF0SglCm_z58kGK24/edit?usp=drive_link",
                "team": "63cec7d96acb3eefd60f5192",
                "_id": "661aebd8ac6b9127e7f930ec"
              },
              {
                "link": "https://docs.google.com/document/d/1jObMZUq4Rk3RxqFDWCwrxADn3LsNS4Ufd6E4pa4riDI/edit?usp=drive_link",
                "team": "63cec7cc6479bf515f0e200a",
                "_id": "661aebd8ac6b9127e7f930ed"
              },
              {
                "link": "https://docs.google.com/document/d/1jCC7dxyYeZflHqMS-ulA4tL-DUjEdAD6nhhyJDKZkZA/edit?usp=drive_link",
                "team": "63cec7c05e0fb380af93bbeb",
                "_id": "661aebd8ac6b9127e7f930ee"
              },
              {
                "link": "https://docs.google.com/document/d/1iTTMzEncccVzA4W3A5NiUMZ3MA0aJJF0K-0p9seeawQ/edit?usp=drive_link",
                "team": "63cec7b852cc59fee86d99f7",
                "_id": "661aebd8ac6b9127e7f930ef"
              },
              {
                "link": "https://docs.google.com/document/d/1ga9kjNAO9ila_3JCoG3Ae9kQhW2E6hDmQQ-0wye5FQ0/edit?usp=drive_link",
                "team": "63cec7ae595af0f6c260dc88",
                "_id": "661aebd8ac6b9127e7f930f0"
              },
              {
                "link": "https://docs.google.com/document/d/1ffr8BTZlcQP5_x_eDFgJhW_YDfTNFfwuTgSWll76TpA/edit?usp=drive_link",
                "team": "63cec7a2944d38be8b20bb58",
                "_id": "661aebd8ac6b9127e7f930f1"
              },
              {
                "link": "https://docs.google.com/document/d/1fJBonKpnQI14lCFlf-NQxJNQB5oCWzQJuhizdtuN-jk/edit?usp=drive_link",
                "team": "63cec78a263c4160d6b86a30",
                "_id": "661aebd8ac6b9127e7f930f2"
              },
              {
                "link": "https://docs.google.com/document/d/1eb4SYNueT4iOSNXtWiXR7hdQhtBwJPQFzLc65tK-_nE/edit?usp=drive_link",
                "team": "63cec77327e778fd433a9326",
                "_id": "661aebd8ac6b9127e7f930f3"
              },
              {
                "link": "https://docs.google.com/document/d/1XjUKKhrnX1WJkQ00PCUUnkYzx-qDEAreDZ3m5nRFAXY/edit?usp=drive_link",
                "team": "63cec768b9057eafd92f54d2",
                "_id": "661aebd8ac6b9127e7f930f4"
              },
              {
                "link": "https://docs.google.com/document/d/1V4Nvzfi_Ksq3ipY2tuivRkf8NmOFZjrc_YskAKVjbLU/edit?usp=drive_link",
                "team": "63cec747d8be0730dded3b03",
                "_id": "661aebd8ac6b9127e7f930f5"
              },
              {
                "link": "https://docs.google.com/document/d/1Q-7VSGPFgkcuJNKDl9oRD4i4dCXJzYHuffM8_0xVq3Y/edit?usp=drive_link",
                "team": "63cec7329652e0c7573289db",
                "_id": "661aebd8ac6b9127e7f930f6"
              },
              {
                "link": "https://docs.google.com/document/d/1NPp3swkEQG2mLtPhmZusBapDRNfD8MO-bEy_7gF1voY/edit?usp=drive_link",
                "team": "63cec725fe064d793156c4f1",
                "_id": "661aebd8ac6b9127e7f930f7"
              },
              {
                "link": "https://docs.google.com/document/d/1KWJolY3mErHsnEn5NSydh1g9AIN2LnZFQ4ppULAVdz8/edit?usp=drive_link",
                "team": "63cec71943d220329ff589d6",
                "_id": "661aebd8ac6b9127e7f930f8"
              },
              {
                "link": "https://docs.google.com/document/d/1EBSC4H-NI6dKxEZGm4WHnTgcBxyItMujT5Oc9AMAZFk/edit?usp=drive_link",
                "team": "63cec70af5c5aa950b3a3e23",
                "_id": "661aebd8ac6b9127e7f930f9"
              },
              {
                "link": "https://docs.google.com/document/d/19pHekHmCo-BFpuLJGXof14PjRi2uG5q82RjMv3uaqPQ/edit?usp=drive_link",
                "team": "63cec6f1e1cf72f4c577d9ae",
                "_id": "661aebd8ac6b9127e7f930fa"
              },
              {
                "link": "https://docs.google.com/document/d/18Z8ITZ3D_GNZKWu8Vi09RR7TvAxqc55D6gcYX13Y1a0/edit?usp=drive_link",
                "team": "63c55f92fb9ffa4575a6c01a",
                "_id": "661aebd8ac6b9127e7f930fb"
              },
              {
                "link": "https://docs.google.com/document/d/17_U-GfVE8N4vcJVIvLdttzhDeYpYfOi4Y49VO9Lwwag/edit?usp=drive_link",
                "team": "63c55f83f0e97b2796521ba0",
                "_id": "661aebd8ac6b9127e7f930fc"
              },
              {
                "link": "https://docs.google.com/document/d/164s-ZDOBsB_3pwzLoHWH9UaPxHsJJorB0TyBE-XYynE/edit?usp=drive_link",
                "team": "63c55f7072839870bf0fcfcc",
                "_id": "661aebd8ac6b9127e7f930fd"
              },
              {
                "link": "https://docs.google.com/document/d/10k9nkrudy3aPPp3pAz8jMTMvcACpOmj0Y88vgI7sJ5w/edit?usp=drive_link",
                "team": "63c55f65330c8ea57559add7",
                "_id": "661aebd8ac6b9127e7f930fe"
              }
            ],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 30,
            "index": 6,
            "_id": "674dac77ccf1fe436e847a9c",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "לחפש מעגלי פגיעה – בני משפחה של הנפגעים, חברים קרובים, אוכלוסיות תל\"מ",
            "comment": null,
            "category": "social",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": true,
            "recurringLength": 30,
            "index": 7,
            "_id": "674dac77ccf1fe436e847ab2",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "חיפוש אחר נפגעים משניים – עדי ראייה, נפגעי הלם ",
            "comment": "אם יש צורך לפתוח מרחב חוסן לעזרה ראשונית",
            "category": "social",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 8,
            "_id": "674dac77ccf1fe436e847ab3",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "היום שאחרי - התערבויות לצוותים – כ\"\"כ, צח\"\"י, עובדי יישוב, מתנדבים אחרים ",
            "comment": null,
            "category": "social",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 9,
            "_id": "674dac77ccf1fe436e847ab4",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "היום שאחרי - התערבויות קהילתיות – שכבת גיל של נפגעים, נוער בכלל, הורים לילדים ",
            "comment": null,
            "category": "social",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 10,
            "_id": "674dac77ccf1fe436e847ab5",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "היום שאחרי - התערבויות קהילתיות ממוקדות לאוכלוסיות ייחודיות – עולים חדשים, מבוגרים וכדו'",
            "comment": null,
            "category": "social",
            "link": null,
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 11,
            "_id": "674dac77ccf1fe436e847ab6",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          },
          {
            "title": "ריכוז מידע והודעות רווחה וחוסן לתושבים",
            "comment": "",
            "category": "social",
            "link": "https://drive.google.com/drive/folders/10aTWyFXKmsj0WbNo_PAANL5yxUOSWvaJ?usp=sharing",
            "differentialLinks": [],
            "isChecked": false,
            "isRecurring": false,
            "recurringLength": null,
            "index": 12,
            "_id": "674dac77ccf1fe436e847ab7",
            "createdAt": "2024-12-02T12:47:51.389Z",
            "updatedAt": "2024-12-02T12:47:51.389Z"
          }
        ],
        "reports": [],
        "createdAt": "2024-12-02T12:47:51.326Z",
        "updatedAt": "2025-02-28T06:42:58.406Z",
        "__v": 1
      }
    ])
        
    // Handle browser refresh or tab close
    window.addEventListener('beforeunload', this.cleanup)
  },
  beforeUnmount() {
    // Remove the event listener when component is unmounted
    window.removeEventListener('beforeunload', this.cleanup)
        
    // This is still useful as a fallback cleanup method
    this.cleanup()
  }
}
</script>

<style lang="scss" scoped>
.example {
    padding: 20px;
}

.example {
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    background-color: var(--backgroundWarm);
    background-image: url('/public/images/warm.png');
    min-height: 100vh;
}
</style>
<style>
#nprogress .bar {
    display: none;
}
#onesignal-bell-container{
    display: none;
}
</style>