<template>
  <v-app :id="appId" style="overflow-x: hidden">
    <my-drawer v-if="showDrawer" v-model="drawer" />
    <v-main>
      <my-navbar v-if="showDrawer" @toggleDrawer="toggleDrawer" />
      <router-view v-if="showRouter" />
    </v-main>
    <close-app-footer v-if="showFooter" />
  </v-app>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import OneSignalVue from 'onesignal-vue'
import { isRtlLang } from './i18n'
import MyNavbar from '@/layouts/Navbar'
import CloseAppFooter from '@/layouts/CloseAppFooter'
import MyDrawer from '@/layouts/Drawer'
import FileSave from '@/services/FilesSave'
import generalWatchers from '@/mixins/generalWatches'

export default {
  name: 'App',
  mixins: [FileSave, generalWatchers],
  components: {
    MyNavbar,
    CloseAppFooter,
    MyDrawer,
  },
  data() {
    return {
      drawer: false,
      showRouter: false,
    }
  },

  computed: {
    online() {
      return this.$store.getters.online
    },
    event() {
      return this.$store.getters.event
    },
    events() {
      return this.$store.getters.events
    },
    snackbar() {
      return this.$store.getters.snackbar
    },
    showFooter() {
      return false
    },
    showDrawer() {
      return !['Login', 'Example'].includes(this.$route.name)
    },
    appId() {
      // TODO: find terms smarter
      if (this.$route.path.includes('cold')) {
        return 'main-layout-cold'
      }
      if (
        this.$route.path.includes('dashboard') ||
        this.$route.path.includes('todo') ||
        this.$route.name.includes('Login')
      ) {
        return 'main-layout-warm'
      }
      return 'main-layout-base'
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    },
  },
  async created() {
    if (this.$store.getters.isAuth) {
      await this.$store.dispatch('offline/init')
      await this.$store.dispatch('socket/join')
      await this.$store.dispatch('event/index')
    }
    this.showRouter = true
    await this.$store.dispatch('eventType/index')
  },
  beforeCreate() {
    this.$OneSignal.on('subscriptionChange', isSubscribed => {
      if (isSubscribed) {
        this.$OneSignal.getUserId(async playerId => {
          this.playerId = playerId
          await this.$store.dispatch('onesignal/register', { playerId })
        })
      } else {
        this.playerId = null
      }
    })
  },
  watch: {
    online: function (newV) {
      if (newV) {
        this.$infoToast('חזרת למצב מקוון', 0, 0, 0, 1)
      }
      if (!newV) {
        this.$warningToast('עברת למצב לא מקוון')
      }
    },
    '$i18n.locale': function (newLocale) {
      this.$vuetify.rtl = isRtlLang(newLocale)
    },
  },
}
</script>
<style lang="scss" scoped>
#main-layout-base {
  //TODO: add style for base layout
}

#main-layout-warm {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  background-color: var(--backgroundWarm);
  background-image: url('/public/images/warm.png');
}

#main-layout-cold {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  background-color: var(--backgroundCold);
  background-image: url('/public/images/cold.png');
}
</style>
