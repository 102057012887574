import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import DashboardUserCold from '../views/DashboardUserCold.vue'
import DashboardAdmin from '../views/DashboardAdmin.vue'
import DashboardUser from '../views/DashboardUser.vue'
import TodoPage from '../views/EventUser.vue'
import EventAdmin from '@/views/EventAdmin.vue'
import store from '@/store'
import StatusReport from '@/views/StatusReport.vue'
import Avuka from '@/views/Avuka.vue'
import Example from '@/views/Example.vue'

Vue.use(VueRouter)

const LoginGuard = (to, from, next) => {
  if (!store.getters.isAuth) {
    return to.name === 'Login' ? next() : next({ name: 'Login' })
  }
  const isWarm = store.getters.isWarm
  return next({ name: isWarm ? 'DashboardUser' : 'DashboardUserCold' })
}
const AdminGuard = (to, from, next) => {
  if (store.getters.isAdmin) {
    return next()
  }
  const isWarm = store.getters.isWarm
  return next({ name: isWarm ? 'DashboardUser' : 'DashboardUserCold' })
}
const stateWarnGuard = (to, from, next) => {
  if (document.location.pathname === '/example') {
    return
  }
  const isWarm = store.getters.isWarm || Boolean(store.getters.event)
  if (to.name === 'DashboardUserCold' && isWarm) {
    next()
  }
  return isWarm ? next() : next({ name: 'DashboardUserCold' })
}
const stateColdGuard = (to, from, next) => {
  const isWarm = store.getters.isWarm || Boolean(store.getters.event)
  return !isWarm ? next() : next({ name: 'DashboardUser' })
}
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: LoginGuard,
  },
  {
    path: '/dashboard-admin',
    name: 'DashboardAdmin',
    component: DashboardAdmin,
    beforeEnter: AdminGuard,
  },
  {
    path: '/event-admin',
    name: 'EventAdmin',
    component: EventAdmin,
    beforeEnter: AdminGuard,
  },
  {
    path: '/dashboard-user',
    name: 'DashboardUser',
    component: DashboardUser,
    beforeEnter: stateWarnGuard,
  },
  {
    path: '/dashboard-user-cold',
    name: 'DashboardUserCold',
    component: DashboardUserCold,
    beforeEnter: stateColdGuard,
  },
  {
    path: '/todo/:category?',
    name: 'TodoPage',
    component: TodoPage,
    beforeEnter: stateWarnGuard,
  },
  {
    path: '/avuka',
    name: 'Avuka',
    component: Avuka,
    beforeEnter: avukaAuth,
  },
  {
    path: '/status-report',
    name: 'StatusReport',
    component: StatusReport,
  },
  {
    path: '/example',
    name: 'Example',
    component: Example,
    // No navigation guard for this route
  },
  {
    path: '*',
    name: 'NotFound',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeResolve((to, from, next) => {
  // Allow access to Example page without authentication
  if (to.name === 'Example' || to.name === 'Login') {
    return next()
  }

  const { isAuth, isAdmin, isWarm, event } = router.app.$store.getters
  const warm = isWarm || Boolean(event)
  if (!isAuth) {
    return to.name === 'Login' ? next() : next({ name: 'Login' })
  }
  if (isAdmin) {
    return to.name !== 'DashboardUser' || to.name !== 'DashboardUserCold'
      ? next()
      : next({ name: 'DashboardAdmin' })
  }
  if (!warm) {
    return { DashboardUserCold: 1, StatusReport: 1 }[to.name]
      ? next()
      : next({ name: 'DashboardUserCold' })
  }
  return next()
})
function avukaAuth(to, from, next) {
  return ['שדה בר', 'נוקדים', 'כפר אלדד'].includes(store.getters.team)
    ? next()
    : next({ name: 'Login' })
}
export default router
