<template>
  <v-badge
    left
    :value="newReportCounter.length"
    offset-x="10"
    offset-y="10"
    color="green"
    :content="newReportCounter.length"
  >
    <v-select
      v-model="eventsSelected"
      :items="events"
      item-value="_id"
      label="בחר אירוע"
      solo
      dense
      multiple
    >
      <template v-slot:selection="{ item, index }">
        <v-row dense v-if="index === 0" class="align-center">
          <v-col cols="2">
            <v-simple-checkbox :value="true" disabled />
          </v-col>
          <span>
            {{ $t(`eventCategories.${item.category}`) }}
          </span>
          <span class="ms-2">
            {{ time(item.createdAt) }}
          </span>
        </v-row>
      </template>
      <template v-slot:item="{ item, attrs }">
        <v-badge
          left
          :value="reportCounterByEventId(item)"
          :content="reportCounterByEventId(item)"
          offset-x="20"
          offset-y="20"
          color="green"
          class="pa-4"
          :style="`width:100%;background-color:${item.color};color:black !important;`"
        >
          <div class="row align-center">
            <v-checkbox v-model="attrs.inputValue" color="white" />
            <span>
              {{ $t(`eventCategories.${item.category}`) }}
            </span>
            <span class="ms-2">
              {{ time(item.createdAt) }}
            </span>
          </div>
          <span class="ms-6 mt-n5" style="display: block; font-size: 12px">{{
            item.content
          }}</span>
        </v-badge>
      </template>
    </v-select>
  </v-badge>
</template>

<script>
import Times from '@/helpers/times.js'
export default {
  name: 'select-event',
  data() {
    return {
      eventsSelected: [],
      colors: ['#4285F4', '#DB4437', '#F4B400', '#0F9D58', '#50C7C7'],
    }
  },
  computed: {
    newReportCounter() {
      return this.$store.getters.newReportCounter
    },
    event() {
      return this.$store.getters.event
    },
    events() {
      return this.$store.getters.events.map((item, index) => ({
        ...item,
        color: this.colors[index],
      }))
    },

    eventFiltered() {
      return this.$store.getters.eventFiltered
    },
  },
  methods: {
    reportCounterByEventId(item) {
      return this.newReportCounter.filter(c => c.eventId === item._id).length
    },
    time(date) {
      return Times.extractTimeString(date)
    },
  },
  mounted() {
    if (this.events.length) {
      const allEventIds = this.events.map(event => event._id)
      this.$store.commit('events/chosen', allEventIds)
      this.eventsSelected = allEventIds
    }
  },

  watch: {
    eventsSelected() {
      this.$store.commit('events/chosen', this.eventsSelected)
    },
  },
}
</script>

<style></style>
